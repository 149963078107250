<template>
  <div
    ref="outer"
    class="hidden md:block bounce fixed z-10 inset-0 pointer-events-none"
  >
    <span ref="block" class="block"
      ><img
        class="block"
        ref="image"
        :class="{ 'shadow-xl': shadow }"
        :src="`${image.url || '../assets/tacb-logo.svg'}`"
    /></span>
  </div>
</template>

<script>
import { shuffle } from 'lodash'

export default {
  name: 'Bounce',
  props: {
    image: {
      type: Object,
      default: null
    },
    start: {
      type: Number,
      default: 0
    },
    shadow: {
      type: Boolean,
      default: true
    },
    rotate: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.outer.style.top =
        document.getElementById('bounce-top').getBoundingClientRect().y + 'px'
      this.$refs.block.animate(
        shuffle([
          { transform: `translateX(calc(100vw - 18rem))` },
          { transform: 'translateX(0)' }
        ]),
        {
          duration: this.randomIntFromInterval(50000, 90000),
          iterations: Infinity,
          iterationStart: Math.random(),
          direction: 'alternate'
        }
      )
      if (this.$props.rotate) {
        this.$refs.image.animate(
          shuffle([
            { transform: 'translateY(0) rotate(0)' },
            {
              transform: `translateY(calc(100vh - ${27.5 *
                this.$props.image.ratio}rem - ${
                document.getElementById('bounce-top').getBoundingClientRect().y
              }px)) rotate(190deg)`
            }
          ]),
          {
            duration: this.randomIntFromInterval(50000, 90000),
            iterations: Infinity,
            iterationStart: Math.random(),
            direction: 'alternate'
          }
        )
      } else {
        this.$refs.image.animate(
          shuffle([
            { transform: 'translateY(0)' },
            {
              transform: `translateY(calc(100vh - ${27.5 *
                this.$props.image.ratio}rem - ${
                document.getElementById('bounce-top').getBoundingClientRect().y
              }px))`
            }
          ]),
          {
            duration: this.randomIntFromInterval(50000, 90000),
            iterations: Infinity,
            iterationStart: Math.random(),
            direction: 'alternate'
          }
        )
      }
    })
  },
  methods: {
    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>

<style lang="css">
.bounce {
  img {
    @apply w-96;
  }
}
</style>
